import React, { useState } from "react"
import MobileColumn from "./MobileColumn"
import {
  TablesWrapper,
  Header,
  Cols,
  Button,
} from "../styles/MobileDropdown.styled"
import chevron from "../assets/chevron-blue.svg"

const MobileDropdown = ({ component }) => {
  const [showTables, setShowTables] = useState(true)
  return (
    <>
      <TablesWrapper showTables={showTables}>
        {component.map(table => (
          <div className="table-wrapper">
            <Header>{table?.title}</Header>
            <Cols>
              {table?.cols?.map((col, idx) => (
                <MobileColumn component={col} colId={idx} />
              ))}
            </Cols>
          </div>
        ))}
      </TablesWrapper>
      <Button
        onClick={() => setShowTables(!showTables)}
        showTables={showTables}
      >
        More Features <img src={chevron}></img>
      </Button>
    </>
  )
}

export default MobileDropdown
