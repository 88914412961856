import React from "react"
import OptimizedImage from "../../../utils/optimizedImage"
import { Button } from "../../../atoms/Button"
import { Col, Icon, Heading, Subhead } from "../styles/ColumnHeaders.styled"

const MobileColumnHeader = ({ component }) => {
  return (
    <Col>
      {component?.icons && (
        <Icon>
          <OptimizedImage
            image={component?.icons[0]?.primaryImage?.gatsbyImageData}
            alt=""
          />
        </Icon>
      )}
      {component?.heading && <Heading>{component?.heading}</Heading>}
      {component?.subheading?.subheading && (
        <Subhead>{component?.subheading?.subheading}</Subhead>
      )}
      {component?.callsToAction && (
        <Button
          ctaLink={component?.callsToAction[0].link}
          ctaVariant={component?.callsToAction[0].variant}
        >
          {component?.callsToAction[0]?.label}
        </Button>
      )}
    </Col>
  )
}

export default MobileColumnHeader
